import { memo } from 'react';
import { Trophy as ProgressIcon } from '@phosphor-icons/react';
import { cx, css } from '@emotion/css';

import cn from './progress.module.scss';

const ProgressBar = ({ className, progress, showComplete = false, completedLabel = '' }) => {

    const _cssProgress = css({
        width: `${progress}%`
    });

    return (
        <div className={cx(cn.container, className)}>
            <div className={cx(cn.label, 'progress__label')}>
                <strong>{progress}%</strong>
            </div>
            <div className={cx(cn.rule, 'progress__rule')}>
                {progress > 0 && <span className={cx(cn.progress, _cssProgress, 'progress__progress')} />}
            </div>
            <ProgressIcon className={cx(cn.ico, 'progress__ico')} />
            {showComplete && (progress >= 100) && <span className={cx(cn.completed, 'progress__text__completed')}>{completedLabel}</span>}
        </div>
    );
};

export default memo(ProgressBar);
