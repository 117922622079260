import { memo } from 'react';
import type { DialogProps as MuiDialogProps } from '@mui/material';
import { Dialog as MuiDialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import cn from './dialog.module.scss';

import { useIsMobile } from '../../hooks/useIsDevice';

export type DialogPropsType = Omit<MuiDialogProps, 'onClose'> & {
    loading?: boolean;
    showCloseButton?: boolean;
    onClose: () => void;
}
const Dialog = ({ onClose, open, loading, children, showCloseButton, ...props }: DialogPropsType) => {

    const isMobile = useIsMobile();

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    return (
        <MuiDialog
            open={open}
            fullScreen={isMobile}
            onClose={handleClose}
            {...props}
        >
            {children}
            {(isMobile || showCloseButton) && (
                <IconButton className={cn.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
        </MuiDialog>
    );

};

export default memo(Dialog);
