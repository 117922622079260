import { memo } from 'react';
import { cx } from '@emotion/css';
import { DialogContent, DialogActions } from '@mui/material';

import type { DialogPropsType } from '../..';
import { SimpleMarkdown, HeadingWithMarkdown, Dialog } from '../..';

import Ellipse from '../../assets/illustrations/Ellipse.svg';
import cn from './dialog.module.scss';

type StyledDialogProps = DialogPropsType & {
    dialogContent: {
        title: string;
        teaser?: string;
        conclusion?: string;
        illustration?: React.ReactNode;
        actions?: React.ReactNode;
    };
}
const StyledDialog = ({ dialogContent, className, ...props }: StyledDialogProps) => {

    const { title, teaser, conclusion, illustration, actions } = dialogContent;

    return (
        <Dialog
            className={cx(cn.container, className)}
            {...props}
        >
            <div className={cn.ellipse}>
                <Ellipse />
            </div>
            <DialogContent className={cx(cn.content, 'content')}>
                <HeadingWithMarkdown heading="h1" className={cx(cn.title, 'title')} value={title} />
                {teaser && <div className={cx(cn.teaser, 'teaser')}>
                    <SimpleMarkdown value={teaser} />
                </div>}
                {illustration && <div className={cx(cn.illustration, 'illustration')}>
                    {illustration}
                </div>}
                {conclusion && <div className={cx(cn.conclusion, 'conclusion')}>
                    <SimpleMarkdown value={conclusion} />
                </div>}
            </DialogContent>
            {actions && <DialogActions className={cx(cn.actions, 'actions')}>
                {actions}
            </DialogActions>}
        </Dialog >
    );

};

export default memo(StyledDialog);
