import { memo } from 'react';
import { cx as cn } from '@emotion/css';

import SimpleMd from './SimpleMd';
import { useCss } from '../theme';

type Props = {
    title?: string;
    body: string;
    emoji?: string;
    actions?: string;
    type?: string;
};
const ToastContent = ({ title, body, emoji, actions, type }: Props) => {
    const css = useCss();

    const cssContainer = css(theme => ({
        display: 'flex',
        alignItems: 'flex-start',
        fontFamily: theme.fonts.roboto,
        '&.noTitle': {
            alignItems: 'center',
            '& p': {
                marginBottom: 0,
            }
        }
    }));
    const cssEmojiContainer = css(theme => ({
        marginRight: theme.spacing(1),
        fontSize: 26,
    }));
    const cssTextsContainer = css({
        display: 'flex',
        flexDirection: 'column',
    });
    const cssTitleContainer = css(theme => ({
        marginBottom: theme.spacing(1),
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    }));
    const cssActions = css(theme => ({
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
        '& button': {
            marginLeft: theme.spacing(0.5),
            color: '#fff',
            borderColor: '#fff',
            '&:hover': {
                borderColor: '#fff',
                backgroundColor: 'rgba(255,255,255,0.2)'
            }
        }
    }));

    return (
        <div className={cn(cssContainer, !title && 'noTitle')} data-ci={`Global.UI.Toastify.${type}`}>
            {emoji && <div className={cssEmojiContainer}>{emoji}</div>}
            <div className={cssTextsContainer}>
                {title && <div className={cssTitleContainer}>{title}</div>}
                <SimpleMd value={body} />
                {actions && (
                    <div className={cssActions}>
                        {actions}
                    </div>
                )}
            </div>
        </div>
    );

};

export default memo(ToastContent);
