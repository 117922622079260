import { memo } from 'react';
import { cx } from '@emotion/css';

import cn from './styles.module.scss';

const Heading = ({ className = '', heading, children }) => {
    const Comp = heading;

    return <Comp className={cx(className, cn[heading])}>
        {children}
    </Comp>;
};

export default memo(Heading);
