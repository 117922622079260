import type { MouseEvent } from 'react';
import { memo, useState, useCallback, forwardRef } from 'react';
import type {
    IconButtonProps as MuiIconButton,
    TextFieldProps as MuiTextFieldProps
} from '@mui/material';
import {
    TextField,
    InputAdornment,
    IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordField = forwardRef((props: MuiTextFieldProps, ref) => {

    const [showPassword, setShowPassword] = useState(false);

    const onChangeShowHide = useCallback(() => setShowPassword(pass => !pass), []);
    return (
        <TextField
            {...props}
            inputRef={ref}
            type={showPassword && !props.disabled ? 'text' : 'password'}
            InputProps={{
                endAdornment: <ShowHidePassw
                    onClick={onChangeShowHide}
                    show={showPassword}
                    disabled={props.disabled}
                />
            }}
        />
    );
});

type ShowHidePasswPropsType = MuiIconButton & {
    show: boolean;
}
const ShowHidePassw = memo(({ onClick, show, disabled }: ShowHidePasswPropsType) => {

    const handleMouseDownPassword = useCallback((e: MouseEvent<HTMLButtonElement>) => e.preventDefault(), []);

    return (
        <InputAdornment position="end">
            <IconButton
                disabled={disabled}
                aria-label="toggle password visibility"
                onClick={onClick}
                onMouseDown={handleMouseDownPassword}
            >
                {show && !disabled ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
});

export default memo(PasswordField);
