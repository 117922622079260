import { memo } from 'react';
import { ButtonBase } from '@mui/material';

import { StyledDialog } from '../..';
import IllustrationEmail from '../../assets/illustrations/IllustrationEmail.svg';
import cn from './banner.module.scss';
import type { BottomBannerProps, ButtonProps, ModalProps } from './types';

const BottomBanner = ({ onClick, ...props }: BottomBannerProps) => {
    const { t, email } = props;

    return (<>
        <div className={cn.bottomBanner}>
            <p>{t('bannerLabel', { email })}</p>
            <Button label={t('buttonLabel')} onClick={onClick} />
        </div>
        <Modal {...props} />
    </>);
};

const Button = memo(({ label, onClick }: ButtonProps) => {
    return <ButtonBase className={cn.button} onClick={onClick}>
        <span>{label}</span>
    </ButtonBase>;
});

const Modal = memo(({ open, onClose, t, email }: ModalProps) => {
    const illustration = <IllustrationEmail />;
    return <StyledDialog
        open={open}
        onClose={onClose}
        className={cn.dialogContent}
        disablePortal
        showCloseButton
        dialogContent={{
            title: t('confirmationDialog.title'),
            teaser: t('confirmationDialog.content', { email }),
            illustration
        }}
    />;
});

export default memo(BottomBanner);
