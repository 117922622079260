/* eslint-disable @typescript-eslint/ban-ts-comment */
import { memo, Suspense, useMemo } from 'react';
import { ThemeProvider as UiThemeProvider } from '_ui';
// @ts-ignore
import { getUniverseCode } from '_config';

import HelmetProvider from './HelmetProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const ThemeProvider = memo(() => {

    const code = getUniverseCode();
    const imports = useMemo(() => [
        // @ts-ignore
        import('./config/themes/shared.js'),
        import(`./config/themes/${code}.js`),
    ], [code]);

    return (
        <UiThemeProvider mode="light" imports={imports}>
            <Suspense>
                <HelmetProvider />
            </Suspense>
        </UiThemeProvider >
    );
});

export default memo(ThemeProvider);
