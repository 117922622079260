import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getEnv } from './env';

const detectionOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'navigator', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'locale',
    lookupFromSubdomainIndex: 0
};

export const loadI18nDict = async () => {

    const { allowedLocales, defaultLocale } = getEnv();

    // eslint-disable-next-line
    await i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            supportedLngs: allowedLocales,
            debug: false,
            fallbackLng: defaultLocale,
            // ns: namespaces,
            resources: {},
            detection: detectionOptions,
        });
    const locale = i18n.language;
    const file = await import(`../i18n/${locale}/index.js`);
    await i18n.addResourceBundle(locale, 'translation', {
        ...file.default
    });
};
