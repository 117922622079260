import { lazy, memo, Suspense } from 'react';
import { isAfter, isBefore } from 'date-fns';

const Alert = lazy(() => import('./Alert'));

// data would be (one day) fetch from back-end, but for now it's hard coded
const SYSTEM_ALERT = {
    from: new Date('2024-05-24T16:00:00'),
    to: new Date('2024-05-27T21:05:00'),
    severity: 'warning',
    content: {
        fr: 'Notre application sera temporairement indisponible le **27 Mai de 21h à 22h** pour des travaux de maintenance programmée. Merci de votre compréhension.',
        en: 'Our application will be temporarily unavailable on **May 27th from 9PM to 10PM** for scheduled maintenance. Thank you for your understanding.'
    }
};

const TODAY = new Date();
const SHOULD_DISPLAY = isAfter(TODAY, SYSTEM_ALERT.from) && isBefore(TODAY, SYSTEM_ALERT.to);

const Wrapper = ({ locale, theme }) => {

    if (!SHOULD_DISPLAY) return null;

    return (
        <Suspense>
            <Alert content={SYSTEM_ALERT.content[locale ?? 'en']} severity={SYSTEM_ALERT.severity} theme={theme} />
        </Suspense>
    );
};

export default memo(Wrapper);
