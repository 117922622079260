import { memo } from 'react';
import type { ButtonProps as MuiButonProps } from '@mui/material';
import { Button, LinearProgress } from '@mui/material';
import { cx as cn } from '@emotion/css';
import { useCss } from '../theme';

type Props = MuiButonProps & {
    label: string;
    loading: boolean;
}

const LoadingButton = ({ label, loading, disabled, className, ...props }: Props) => {

    const css = useCss();

    const cssProgress = css({
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 5,
    });
    const cssButton = css({
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    });

    return (
        <Button
            {...props}
            disabled={loading || disabled}
            className={cn(cssButton, className)}
            variant="contained"
            color="secondary"
            disableElevation
        >
            {label}
            {loading && <LinearProgress color="secondary" className={cssProgress} />}
        </Button>
    );
};

export default memo(LoadingButton);
