import { memo } from 'react';

import cn from './chip.module.scss';

type PropsType = {
    content: string;
}
const Chip = ({ content }: PropsType) => {

    return (
        <div className={cn.container}>
            <span>{content}</span>
        </div>
    );
};

export default memo(Chip);
