import { memo, useMemo } from 'react';
import { Alert as MuiAlert, AlertTitle, IconButton, Collapse } from '@mui/material';
import { Close } from '@mui/icons-material';
import { cx } from '@emotion/css';
import SimpleMd from '../SimpleMd';

import type { AlertProps as MuiAlertProps } from '@mui/material';

import cn from './style.module.scss';

type AlertProps = MuiAlertProps & {
    content: string;
    title?: string;
    theme?: 'light' | 'dark';
}
type ClosableAlertProps = AlertProps & {
    show: boolean;
    onClose: () => void;
}

const Alert = ({ severity = 'info', theme = 'light', content, title, children, className, ...props }: AlertProps) => {

    return (
        <MuiAlert severity={severity} className={cx(cn.alert, cn[`theme--${theme}`], cn[`severity--${severity}`], className)} {...props}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {content && <SimpleMd value={content} linkTarget="_blank" />}
            {children}
        </MuiAlert>
    );
};

export const ClosableAlert = ({ show, onClose, ...props }: ClosableAlertProps) => {
    const Action = useMemo(() => (
        <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
        >
            <Close fontSize="inherit" />
        </IconButton>
    ), [onClose]);

    return (
        <Collapse in={show}>
            <Alert {...props} action={Action} />
        </Collapse>
    );
};

export default memo(Alert);
