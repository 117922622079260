import { useEffect, memo, useRef, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { getTheme, loadTheme } from '../theme';

const ThemeProvider = ({ mode, imports, children, loader = null }) => {
    const aThemeHasBeenLoaded = useRef(false);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        (async () => {
            setLoaded(false);
            await loadTheme(mode, imports);
            aThemeHasBeenLoaded.current = true;
            setLoaded(true);
        })();
    }, [mode, imports]);

    if (!loaded && !aThemeHasBeenLoaded.current) return loader;
    return (
        <ThemeWrapper>
            {children}
        </ThemeWrapper>
    );
};

// This one should not be wrapped in memo, as when parent finished loading the new theme, this one need to be re-render to get the actual theme. If we keep memo here, the theme would never change
const ThemeWrapper = ({ children }) => {

    const theme = getTheme();

    return (
        <MuiThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                {children}
            </StyledEngineProvider>
        </MuiThemeProvider>
    );
};

export default memo(ThemeProvider);
