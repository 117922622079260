import { memo } from 'react';
import MarkdownRender from 'react-markdown';
import Heading from '../Heading';

// This prevent rendering initial <p> tag
const DISALLOWED_ELEMENTS = ['p'];
const HeadingWithMarkdown = ({ value, ...props }) => {

    return (
        <Heading {...props}>
            <MarkdownRender children={value} disallowedElements={DISALLOWED_ELEMENTS} unwrapDisallowed />
        </Heading>
    );

};

export default memo(HeadingWithMarkdown);
