/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { useCallback } from 'react';
import { css } from '@emotion/css';

import type { CSSObject } from '@emotion/react';

// estlint-disable-next-line
const _THEMES = {};

let _THEME: any;
export const getTheme = () => {
    if (!_THEME) throw new Error('Error, you must set theme using setTheme before using ui components');
    return _THEME;
};

type TypeThemeMode = 'light' | 'dark';
export const loadTheme = async (mode: TypeThemeMode, imports = []) => {
    // @ts-ignore
    if (_THEMES[mode]) {
        // @ts-ignore
        _THEME = _THEMES[mode];
        return _THEME;
    }

    const [sharedData, modeData, ...all] = await Promise.all([
        // @ts-ignore
        import('./themes/shared.js'),
        import(`./themes/${mode}.js`),
        ...imports
    ]);

    // @ts-ignore
    const importsData = (all ?? []).reduce((prev, curr) => deepmerge(prev, curr.default), {});

    // @ts-ignore
    _THEMES[mode] = responsiveFontSizes(createTheme(deepmerge({
        ...sharedData.default,
        ...modeData.default
    }, importsData)));
    // @ts-ignore
    _THEME = _THEMES[mode];
    return _THEME;
};

export const useTheme = useMuiTheme;

// eslint-disable-next-line no-unused-vars
type P = (a: any) => CSSObject
export const useCss = () => {
    const theme = useTheme();

    const _css = useCallback((p: CSSObject | P) => {
        if (p instanceof Function) return css(p(theme));

        // parameter is not a function but an object, we execute directly css
        return css(p);

    }, [theme]);

    return _css;
};

export const useIsMobile = () => {
    const theme = useTheme();
    // @ts-ignore
    const isMobile = useMediaQuery(theme.bp.mobile);

    return isMobile;
};
