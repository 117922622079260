import { memo } from 'react';
import { ToastContainer as TC } from 'react-toastify';
import { useCss } from '../theme';

const ToastContainer = () => {

    const css = useCss();

    const cssContainer = css({
        padding: 0
    });
    const cssToast = css(theme => ({
        marginBottom: 0,
        padding: theme.margin / 2,
        marginTop: 10
    }));

    return (
        <TC
            autoClose={5000}
            position="bottom-right"
            closeOnClick
            pauseOnHover
            draggable
            newestOnTop
            className={cssContainer}
            toastClassName={cssToast}
            draggablePercent={34}
            icon={false}
            theme="colored"
        />
    );

};

export default memo(ToastContainer);
