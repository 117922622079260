import { Suspense, lazy, memo } from 'react';
import { Helmet, HelmetProvider as Provider } from 'react-helmet-async';

import { getEnv } from './config/env';

const App = lazy(() => import('./App'));

const HelmetProvider = () => {

    const { universe, schoolName } = getEnv();
    const getUniverseAssetUrl = (asset: string) => `/globals/${universe}/${asset}`;

    return (
        <Provider>
            <Suspense>
                <Helmet>
                    <link rel="apple-touch-icon" sizes="180x180" href={getUniverseAssetUrl('apple-touch-icon.png')} />
                    <link rel="icon" type="image/png" sizes="32x32" href={getUniverseAssetUrl('favicon-32x32.png')} />
                    <link rel="icon" type="image/png" sizes="16x16" href={getUniverseAssetUrl('favicon-16x16.png')} />
                    <link rel="icon" href={getUniverseAssetUrl('favicon.ico')} />
                    <title>Login • {schoolName}</title>
                </Helmet>
                <App />
            </Suspense>
        </Provider>
    );
};

export default memo(HelmetProvider);
