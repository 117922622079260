import { memo } from 'react';
import { cx } from '@emotion/css';

import cn from './burger.module.scss';

const FancyBurgerMenu = ({ active = false, onClick }) => {

    return (<>
        <svg>
            <defs>
                <filter id="gooeyness">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="2.2" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10" result="gooeyness" />
                    <feComposite in="SourceGraphic" in2="gooeyness" operator="atop" />
                </filter>
            </defs>
        </svg>
        <button className={cx(cn.plate, active && cn.active)} onClick={onClick}>
            <svg className={cx(cn.burger, cn.svg)} version="1.1" height="100" width="100" viewBox="0 0 100 100">
                <path className={cx(cn.line, cn.line1)} d="M 50,35 H 30" />
                <path className={cx(cn.line, cn.line2)} d="M 50,35 H 70" />
                <path className={cx(cn.line, cn.line3)} d="M 50,50 H 30" />
                <path className={cx(cn.line, cn.line4)} d="M 50,50 H 70" />
                <path className={cx(cn.line, cn.line5)} d="M 50,65 H 30" />
                <path className={cx(cn.line, cn.line6)} d="M 50,65 H 70" />
            </svg>
            <svg className={cx(cn.x, cn.svg)} version="1.1" height="100" width="100" viewBox="0 0 100 100">
                <path className={cn.line} d="M 34,32 L 66,68" />
                <path className={cn.line} d="M 66,32 L 34,68" />
            </svg>
        </button>
    </>);

};

export default memo(FancyBurgerMenu);
