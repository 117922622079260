import { memo, useMemo } from 'react';

import ReactMarkdown from 'react-markdown';
import type { PluggableList } from 'react-markdown/lib';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeExternalLinks from 'rehype-external-links';

type Props = {
    value: string;
    linkTarget?: string;
}

const REMARK_PLUGINS = [remarkGfm, remarkBreaks];

const SimpleMd = ({ value, linkTarget }: Props) => {

    const rehypePlugins: PluggableList = useMemo(() => {
        return [[rehypeExternalLinks, { target: linkTarget }]];
    }, [linkTarget]);

    return (
        <ReactMarkdown
            children={value}
            remarkPlugins={REMARK_PLUGINS}
            rehypePlugins={rehypePlugins}
        />
    );

};

export default memo(SimpleMd);
