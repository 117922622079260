import { memo } from 'react';
import { getEnv } from '_config';
import cn from '../../scss/appbar.module.scss';

type Props = {
    type: string;
}
const AppBarLogo = ({ type, ...props }: Props) => {
    const { universe, schoolName } = getEnv();
    return <img className={cn.logo} src={`/assets/logos/${universe}/${type}.png`} alt={`Logo ${schoolName}`} {...props} />;

};

export default memo(AppBarLogo);
