import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import ThemeProvider from './ThemeProvider';

import { loadEnv } from './config/env';
import { loadI18nDict } from './config/i18n';

const init = async () => {

    await loadEnv();
    await loadI18nDict();

    ReactDOM.createRoot(document.getElementById('root')).render(
        // <React.StrictMode>
        <Suspense>
            <ThemeProvider />
        </Suspense>
        // </React.StrictMode>,
    );

};

init();
