import { memo } from 'react';
import type { CheckboxProps, FormControlLabelProps } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxLabel = memo(({ label, labelPlacement = 'end', ...props }: CheckboxProps & Partial<FormControlLabelProps>) => {
    return (
        <FormControlLabel labelPlacement={labelPlacement} control={<Checkbox {...props} />} label={label} />
    );
});

export default memo(CheckboxLabel);
