import { memo } from 'react';
import { cx } from '@emotion/css';
import { Clock as TimerIcon } from '@phosphor-icons/react';
import { minutesToHours } from '_utils/date';

import cn from './estimatedTime.module.scss';

const EstimatedTime = ({ estimatedTime, className }) => {
    if (!estimatedTime) return null;
    return (
        <div className={cx(cn.eta, className)}>
            <TimerIcon />
            <span>
                {minutesToHours(estimatedTime)}
            </span>
        </div>
    );
};

export default memo(EstimatedTime);
